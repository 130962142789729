// @mui
import { Alert, Box, Container, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';

import Image from '../../components/Image';
// sections
import { Club } from 'src/graphql/generated';
import useAuth from 'src/hooks/useAuth';
import { LoginForm } from '../../sections/auth/login';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '50%',
  backgroundColor: '#202020',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

// ----------------------------------------------------------------------

export default function ClubLogin({ club }: { club: Club }) {
  const mdUp = useResponsive('up', 'md');
  const { registeringName } = useAuth();

  const { name, company, clubWebPortal } = club;
  const { logoImageUrl } = company;

  return (
    <Page title={`${name} login`}>
      <RootStyle>
        <HeaderStyle>
          <Image src={logoImageUrl || ''} sx={{ height: 84, width: 84 }} />
        </HeaderStyle>
        {mdUp && (
          <SectionStyle
            style={{
              backgroundColor: clubWebPortal?.backgroundColor || undefined,
            }}
          >
            <Typography
              variant="h2"
              sx={{ px: 5, mt: 10, mb: 5, color: clubWebPortal?.textColor || '#fff' }}
            >
              {clubWebPortal?.welcomeText || 'Welcome'}
            </Typography>
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            {registeringName && (
              <Alert sx={{ mb: 2 }}>
                <Typography sx={{ color: 'text.secondary' }}>
                  Thanks for signing up {registeringName}! You can now log in
                </Typography>
              </Alert>
            )}
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  {club.name} Member login
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  {clubWebPortal?.logInText || 'Enter your details below.'}
                </Typography>
              </Box>
            </Stack>
            <LoginForm club={club} />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
