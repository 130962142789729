// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { LicenseInfo } from '@mui/x-license-pro';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import NotistackProvider from './components/NotistackProvider';
import { ProgressBarStyle } from './components/ProgressBar';
import ScrollToTop from './components/ScrollToTop';
import ThemeSettings from './components/settings';

// ----------------------------------------------------------------------

LicenseInfo.setLicenseKey(
  'a5ec08cbc92ab53b32ed299f9bba7656Tz01NTY2NSxFPTE3MDE5MzI4MTAzNTUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <ProgressBarStyle />
            <ScrollToTop />
            <Router />
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
