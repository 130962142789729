import { useParams } from 'react-router';
import { Outlet } from 'react-router-dom';
// @mui
import { Box, Stack } from '@mui/material';
//
import { useEffect } from 'react';
import LoadingScreen from 'src/components/LoadingScreen';
import { Club, useGetClubLazyQuery } from 'src/graphql/generated';
import useClub from 'src/hooks/useClub';
import ClubFooter from './ClubFooter';
import ClubTheme from './ClubTheme';

// ----------------------------------------------------------------------

export type ClubLayoutContext = {
  club: Club;
};

export default function ClubLayout() {
  const params = useParams();
  const { onSetClub, club } = useClub();

  const clubId = params.clubId || 'Unknown';

  const [getClubLazyQuery, { loading }] = useGetClubLazyQuery({
    context: { clientName: 'public' },
    variables: {
      id: clubId,
    },
  });

  useEffect(() => {
    const fetchClub = async () => {
      const result = await getClubLazyQuery();
      const club = result.data?.getClub as Club;
      if (club) {
        onSetClub(club);
      }
    };
    fetchClub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubId]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (!club || !club.clubWebPortal) {
    return null;
  }

  return (
    <ClubTheme clubWebPortal={club.clubWebPortal}>
      <Stack sx={{ minHeight: 1 }}>
        <Outlet context={{ club }} />

        <Box sx={{ flexGrow: 1 }} />

        <ClubFooter club={club} />
      </Stack>
    </ClubTheme>
  );
}
