// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/auth/signin'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  oauth: path(ROOTS_AUTH, '/oauth'),
};

export const PATH_PAGE = {
  page403: '/403',
  page404: '/404',
  page500: '/500',
};

export const PATHS = {
  root: ROOTS_DASHBOARD,
  dashboard: {
    overall: path(ROOTS_DASHBOARD, '/app'),
  },
  club: {
    root: (clubId: string) => path(`${clubId}`, '/'),
    schedule: (clubId: string) => path(``, `/${clubId}/schedule`),
    memberships: (clubId: string) => path(``, `/${clubId}/memberships`),
    purchaseMembership: (clubId: string, membershipId: string) =>
      path(``, `/${clubId}/memberships/purchase/${membershipId}`),
    trials: (clubId: string) => path(``, `/${clubId}/trials`),
    packages: (clubId: string) => path(``, `/${clubId}/packages`),
    signIn: (clubId: string) => path(``, `/${clubId}/signIn`),
    signUp: (clubId: string) => path(``, `/${clubId}/signUn`),
    invoice: (clubId: string, invoiceId: string) => path(``, `/${clubId}/invoice/${invoiceId}`),
    redeem: (clubId: string) => path(``, `/${clubId}/redeem`),
    oauth: (clubId: string) => path(``, `/${clubId}/oauth`),
    dashboard: (clubId: string) => path(``, `/${clubId}/secure/dashboard`),
    classes: (clubId: string) => path(``, `/${clubId}/secure/classes`),
    membership: (clubId: string) => path(``, `/${clubId}/secure/membership`),
    editPaymentFrequency: (clubId: string) => path(``, `/${clubId}/secure/membership/edit`),
    suspendMembership: (clubId: string) => path(``, `/${clubId}/secure/membership/suspend`),
    cancelMembership: (clubId: string) => path(``, `/${clubId}/secure/membership/cancel`),
    paymentDetails: (clubId: string) => path(``, `/${clubId}/secure/payments`),
    membershipSelect: (clubId: string) => path('', `/${clubId}/secure/membership/select`),
    membershipPayment: (clubId: string) => path('', `/${clubId}/secure/membership/payment`),
    membershipSignature: (clubId: string) => path('', `/${clubId}/secure/membership/signature`),
  },
  settings: {
    root: (clubId: string) => path(``, `/${clubId}/secure/settings`),
    profile: (clubId: string) => path(``, `/${clubId}/secure/settings/profile`),
    preferences: (clubId: string) => path(``, `/${clubId}/secure/settings/preferences`),
    notifications: (clubId: string) => path(``, `/${clubId}/secure/settings/notifications`),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
