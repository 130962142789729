export const Config = {
  Auth: {
    region: "ap-southeast-2",
    userPoolId: "ap-southeast-2_hjfyaUlUX",
    userPoolWebClientId: "7pm3a5ks5djclr9onqclnalqtn",
    oauth: {
      domain: "member-identity.club-staging.net",
      redirectSignIn: "https://members.club-staging.net/auth/oauth",
      redirectSignOut: "https://members.club-staging.net/signin",
      scope: ["email", "openid", "phone", "profile", "aws.cognito.signin.user.admin"],
      responseType: "code",
    },
  },
  AppSync: {
    Member: {
      graphqlEndpoint:  "https://api.member.club-staging.net/graphql",
      region: "ap-southeast-2",
    },
    Public: {
      graphqlEndpoint:  "https://api.public.club-staging.net/graphql",
      region: "ap-southeast-2",
    },
 }
};
export default Config;
