import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
import useAuth from '../../../hooks/useAuth';

// components
import { Club } from 'src/graphql/generated';
import MyAvatar from '../../../components/MyAvatar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

type Props = {
  isCollapse: boolean | undefined;
  club: Club;
  handleClick: VoidFunction;
};

export default function NavbarAccount({ isCollapse, club, handleClick }: Props) {
  const { currentMember } = useAuth();

  return (
    <RootStyle
      onClick={handleClick}
      sx={{
        ...(isCollapse && {
          bgcolor: 'transparent',
        }),
      }}
    >
      <MyAvatar />

      <Box
        sx={{
          ml: 2,
          transition: (theme) =>
            theme.transitions.create('width', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(isCollapse && {
            ml: 0,
            width: 0,
          }),
        }}
      >
        {!isCollapse && (
          <>
            <Typography variant="subtitle1" noWrap>
              {currentMember?.displayName}
            </Typography>
            <Typography variant="caption" color={'GrayText'}>
              {club.name}
            </Typography>
          </>
        )}
      </Box>
    </RootStyle>
  );
}
