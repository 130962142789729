import { Link as RouterLink } from 'react-router-dom';
import Box, { BoxProps } from '@mui/material/Box';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const logo = (
    <Box sx={{ width: 54, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
        <rect x="44" width="411" height="68" rx="28" fill="#3D7CC9" />
        <rect x="106" y="93" width="349" height="68" rx="28" fill="#008556" />
        <rect y="186" width="455" height="68" rx="28" fill="#00BC70" />
        <rect x="163" y="279" width="292" height="68" rx="28" fill="#F7AC02" />
        <rect x="309" y="372" width="146" height="68" rx="28" fill="#FFDE00" />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
