import { createContext, ReactNode, useCallback, useEffect, useReducer } from 'react';

// @types
import { ActionMap, AuthState, AWSCognitoContextType } from '../@types/auth';
//
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';

import {
  Club,
  GymGoer,
  Member,
  useGetMeGymGoerLazyQuery,
  useGetMemberLazyQuery,
} from 'src/graphql/generated';

// ----------------------------------------------------------------------

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  currentClub: null,
  currentMemberId: null,
  currentMember: null,
};

enum Types {
  auth = 'AUTHENTICATE',
  register = 'REGISTER',
  confirm = 'CONFIRM',
  logout = 'LOGOUT',
  setCurrentClub = 'SET_CURRENT_CLUB',
  setCurrentMember = 'SET_CURRENT_MEMBER',
  setCurrentMemberId = 'SET_CURRENT_MEMBER_ID',
  resetPassword = 'RESET_PASSWORD',
  resetPasswordFinished = 'RESET_PASSWORD_FINISHED',
}

type AwsAuthPayload = {
  [Types.auth]: {
    isAuthenticated: boolean;
    user: null | GymGoer;
  };
  [Types.register]: {
    registeringName: string;
    registeringEmail: string;
  };
  [Types.logout]: undefined;
  [Types.setCurrentClub]: {
    currentClub: null | Club;
  };
  [Types.setCurrentMember]: {
    currentMember: null | Member;
  };
  [Types.setCurrentMemberId]: {
    currentMemberId: string;
  };
  [Types.resetPassword]: {
    resettingEmail: string;
  };
  [Types.resetPasswordFinished]: undefined;
};

type AwsActions = ActionMap<AwsAuthPayload>[keyof ActionMap<AwsAuthPayload>];

const reducer = (state: AuthState, action: AwsActions) => {
  if (action.type === 'AUTHENTICATE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }
  if (action.type === 'REGISTER') {
    const { registeringEmail, registeringName } = action.payload;
    return {
      ...state,
      registeringName,
      registeringEmail,
    };
  }
  if (action.type === 'SET_CURRENT_CLUB') {
    const { currentClub } = action.payload;
    const { user } = state;
    if (!user) {
      return {
        ...state,
        currentClub,
      };
    }
    const memberships = user.memberships?.filter((m) => m?.companyId === currentClub?.company.id);
    const membership = memberships?.[0];
    return {
      ...state,
      currentClub,
      currentMemberId: membership?.memberId || null,
    };
  }
  if (action.type === 'SET_CURRENT_MEMBER') {
    const { currentMember } = action.payload;
    return {
      ...state,
      currentMember,
    };
  }
  if (action.type === 'SET_CURRENT_MEMBER_ID') {
    const { currentMemberId } = action.payload;
    return {
      ...state,
      currentMemberId,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  if (action.type === 'RESET_PASSWORD') {
    const { resettingEmail } = action.payload;
    return {
      ...state,
      resettingEmail,
    };
  }
  if (action.type === 'RESET_PASSWORD_FINISHED') {
    return {
      ...state,
      resettingEmail: undefined,
    };
  }
  return state;
};

const AuthContext = createContext<AWSCognitoContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [getMe] = useGetMeGymGoerLazyQuery();

  const [getMemberLazyQuery] = useGetMemberLazyQuery({
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

  const getSession = useCallback(async () => {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const userAttributes = await getMe();
    const user = userAttributes.data?.getMeGymGoer as GymGoer;
    dispatch({
      type: Types.auth,
      payload: { isAuthenticated: true, user },
    });
    return {
      user,
      session,
      headers: { Authorization: token },
    };
  }, [getMe]);

  const initial = useCallback(async () => {
    try {
      await getSession();
    } catch {
      dispatch({
        type: Types.auth,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [getSession]);

  useEffect(() => {
    initial();
  }, [initial]);

  const reloadUser = useCallback(async () => {
    const userAttributes = await getMe();
    const user = userAttributes.data?.getMeGymGoer as GymGoer;
    dispatch({
      type: Types.auth,
      payload: { isAuthenticated: true, user },
    });
    return {
      user,
    };
  }, [getMe]);

  const reloadMember = useCallback(async () => {
    const loadMember = async () => {
      if (!state.currentMemberId) {
        return;
      }
      const result = await getMemberLazyQuery({
        variables: {
          id: state.currentMemberId,
        },
      });
      dispatch({
        type: Types.setCurrentMember,
        payload: { currentMember: result.data?.getMember as Member },
      });
    };

    await loadMember();
  }, [getMemberLazyQuery, state.currentMemberId]);

  useEffect(() => {
    const loadMember = async () => {
      if (!state.currentMemberId) {
        return;
      }
      const result = await getMemberLazyQuery({
        variables: {
          id: state.currentMemberId,
        },
      });
      dispatch({
        type: Types.setCurrentMember,
        payload: { currentMember: result.data?.getMember as Member },
      });
    };

    loadMember();
  }, [getMemberLazyQuery, state.currentMemberId]);

  useEffect(() => {
    initial();
  }, [initial]);

  // We make sure to handle the user update here, but return the resolve value in order for our components to be
  // able to chain additional `.then()` logic. Additionally, we `.catch` the error and "enhance it" by providing
  // a message that our React components can use.
  const login = useCallback(
    async (email, password) => {
      await Auth.signIn({ username: email, password });
      await getSession();
    },
    [getSession]
  );

  // same thing here
  const logout = async () => {
    await Auth.signOut();
    dispatch({ type: Types.logout });
  };

  const googleSignIn = async () => {
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
  };

  const forgotPassword = async (email: string) => {
    await Auth.forgotPassword(email);
    dispatch({ type: Types.resetPassword, payload: { resettingEmail: email } });
  };

  const resetPassword = async (validationCode: string, newPassword: string) => {
    const email = state.resettingEmail;
    if (!email) {
      return;
    }
    await Auth.forgotPasswordSubmit(email, validationCode, newPassword);
    dispatch({ type: Types.resetPasswordFinished });
  };

  const changePassword = async (currentPassword: string, newPassword: string) => {
    await Auth.currentAuthenticatedUser()
      .then((user) => Auth.changePassword(user, currentPassword, newPassword))
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  };

  const register = async (
    email: string,
    password: string,
    firstName: string,
    recaptchaToken: string
  ) => {
    dispatch({
      type: Types.register,
      payload: {
        registeringEmail: email,
        registeringName: firstName,
      },
    });
    await Auth.signUp({
      username: email,
      password: password,
      attributes: {
        email: email,
        given_name: firstName,
      },
      validationData: {
        recaptchaToken,
      },
    });
    await login(email, password);
  };

  const setCurrentClub = (club: Club) => {
    dispatch({
      type: Types.setCurrentClub,
      payload: {
        currentClub: club,
      },
    });
  };

  const setMemberId = (currentMemberId: string) => {
    dispatch({
      type: Types.setCurrentMemberId,
      payload: {
        currentMemberId,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'cognito',
        user: state.user,
        currentClub: state.currentClub,
        currentMemberId: state.currentMemberId,
        login,
        register,
        googleSignIn,
        reloadUser,
        reloadMember,
        logout,
        forgotPassword,
        resetPassword,
        changePassword,
        setCurrentClub,
        setMemberId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
