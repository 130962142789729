import { GymGoerNotification, GymGoerNotificationType } from 'src/graphql/generated';

export const descriptionForNotification = (notification: GymGoerNotification) => {
  switch (notification.type) {
    case GymGoerNotificationType.ClassCancelled:
      return 'Class has been cancelled';
    case GymGoerNotificationType.ClassTimeChanged:
      return 'Class time has been changed';
    case GymGoerNotificationType.WaitlistSpotAvailable:
      return 'Waitlist spot is now available';
    case GymGoerNotificationType.WaitlistSpotBooked:
      return 'Waitlist spot has been booked';
    default:
      return '';
  }
};
