import { useEffect, useState } from 'react';
// @mui
import {
  Badge,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import { noCase } from 'change-case';
import {
  GetMyGymGoerNotificationsQueryResult,
  GymGoerNotification,
  useGetMyGymGoerNotificationsLazyQuery,
  useMarkAllGymGoerNotificationsAsReadMutation,
} from 'src/graphql/generated';
import { descriptionForNotification } from 'src/utils/notificationHelpers';
import { IconButtonAnimate } from '../../../components/animate';
import Iconify from '../../../components/Iconify';
import MenuPopover from '../../../components/MenuPopover';
import Scrollbar from '../../../components/Scrollbar';

// ----------------------------------------------------------------------

const now = new Date(Date.now()).toISOString();

export default function NotificationsPopover() {
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const [newNotifications, setNewNotifications] = useState<GymGoerNotification[]>([]);
  const [oldNotifications, setOldNotifications] = useState<GymGoerNotification[]>([]);
  const [totalUnRead, setTotalUnread] = useState<number>(0);

  const [markAllGymGoerNotificationsAsReadMutation] =
    useMarkAllGymGoerNotificationsAsReadMutation();
  const [getMyGymGoerNotificationsLazyQuery, { loading }] = useGetMyGymGoerNotificationsLazyQuery({
    variables: {
      limit: 10,
      before: now,
    },
  });

  useEffect(() => {
    const loadNotifications = async () => {
      const result = await getMyGymGoerNotificationsLazyQuery();
      reloadState(result);
    };
    loadNotifications();
  }, [getMyGymGoerNotificationsLazyQuery]);

  const reloadState = (result: GetMyGymGoerNotificationsQueryResult) => {
    if (!result.data?.getMyGymGoerNotifications) {
      return;
    }
    const latestRead = result.data.getMyGymGoerNotifications.latestRead || '';
    setTotalUnread(result.data.getMyGymGoerNotifications.unreadCount || 0);
    const notifications = (result.data.getMyGymGoerNotifications.items ||
      []) as GymGoerNotification[];

    setNewNotifications(notifications.filter((n) => n.created > latestRead));
    setOldNotifications(notifications.filter((n) => n.created <= latestRead));
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const reload = async () => {
    const result = await getMyGymGoerNotificationsLazyQuery();
    reloadState(result);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = async () => {
    await markAllGymGoerNotificationsAsReadMutation();
    const result = await getMyGymGoerNotificationsLazyQuery();
    reloadState(result);
  };

  const linkForNotification = (notification: GymGoerNotification) => {
    switch (notification.type) {
      default:
        return '';
    }
  };

  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {!loading && (
            <Tooltip title=" Reload">
              <IconButton color="primary" onClick={reload}>
                <Iconify icon="ion:reload" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
          {loading && (
            <Tooltip title=" Reload">
              <CircularProgress />
            </Tooltip>
          )}

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        {newNotifications.length + oldNotifications.length > 0 && (
          <>
            <Divider sx={{ borderStyle: 'dashed' }} />
            <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
              {newNotifications.length > 0 && (
                <List
                  disablePadding
                  subheader={
                    <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                      New
                    </ListSubheader>
                  }
                >
                  {newNotifications.map((notification) => {
                    const to = linkForNotification(notification);
                    return (
                      <NotificationItem
                        key={notification.id}
                        notification={notification}
                        to={to}
                        isUnRead={true}
                      />
                    );
                  })}
                </List>
              )}

              {oldNotifications.length > 0 && (
                <List
                  disablePadding
                  subheader={
                    <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                      Read
                    </ListSubheader>
                  }
                >
                  {oldNotifications.map((notification) => {
                    const to = linkForNotification(notification);
                    return (
                      <NotificationItem
                        key={notification.id}
                        notification={notification}
                        to={to}
                        isUnRead={false}
                      />
                    );
                  })}
                </List>
              )}
            </Scrollbar>
          </>
        )}

        {/* <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box> */}
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

function NotificationItem({
  notification,
  isUnRead,
  to,
}: {
  notification: GymGoerNotification;
  isUnRead: boolean;
  to: string;
}) {
  const { title } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        bgcolor: isUnRead ? 'action.selected' : undefined,
      }}
      component={RouterLink}
      to={to}
    >
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.created)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification: GymGoerNotification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(descriptionForNotification(notification) || '')}
      </Typography>
    </Typography>
  );

  return {
    title,
  };
}
