// @mui
import { styled } from '@mui/material/styles';
import { Grid, Divider, Container, Typography, Stack } from '@mui/material';
// components
import Logo from '../../components/Logo';
import { Address, Club } from 'src/graphql/generated';

// ----------------------------------------------------------------------
export const CLUB_FOOTER_HEIGHT = 110;

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  height: `${CLUB_FOOTER_HEIGHT}px`,
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function ClubFooter({ club }: { club: Club }) {
  const address = JSON.parse(club.address || '') as Address;

  return (
    <RootStyle>
      <Divider />
      <Container
        sx={{
          mb: 2,
          mt: 2,
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid
            item
            xs={12}
            sx={{ mb: 3, flexDirection: 'row', display: 'flex', alignItems: 'center' }}
          >
            <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
            <Typography variant="body2" sx={{ pr: { md: 5 }, ml: 2 }}>
              Powered by Journey Member Management
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
          <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
            <Stack spacing={1}>
              <Typography component="p" variant="overline">
                {club.company.name}
              </Typography>
              <Typography variant="body2">{address.fullText}</Typography>
            </Stack>
          </Stack>
        </Grid>
      </Container>
    </RootStyle>
  );
}
