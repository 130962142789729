import { ElementType, lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// layouts
import ClubLayout from '../layouts/club';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import MainLayout from '../layouts/main';

import SecureClubLayout from 'src/layouts/secureClub';
import SettingsLayout from 'src/layouts/settings';

// guards
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';

// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'signin',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: 'oauth',
          element: (
            <AuthGuard>
              <OAuth />
            </AuthGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: 'app', element: <GeneralApp /> }],
    },
    // Public club routes
    {
      path: ':clubId',
      element: <ClubLayout />,
      children: [
        { path: 'schedule', element: <Schedule />, index: true },
        { path: 'memberships', element: <Memberships /> },
        { path: 'memberships/purchase/:membershipId', element: <PurchaseMembership /> },
        { path: 'invoice/:id', element: <Invoice /> },
        { path: 'signIn', element: <Login /> },
        { path: 'redeem', element: <Redeem /> },
        {
          path: 'oauth',
          element: (
            <AuthGuard>
              <OAuth />
            </AuthGuard>
          ),
        },
        {
          path: 'secure',
          element: <SecureClubLayout />,
          children: [
            { path: 'dashboard', element: <ClubDashboard />, index: true },
            { path: 'classes', element: <ClubClasses /> },
            { path: 'membership', element: <ClubMembership /> },
            { path: 'membership/edit', element: <ClubChangePaymentFrequency /> },
            { path: 'membership/cancel', element: <ClubCancelMembership /> },
            { path: 'membership/suspend', element: <ClubSuspendMembership /> },
            { path: 'payments', element: <ClubPaymentDetails /> },
            { path: 'membership/payment', element: <MembershipPayment /> },
            { path: 'membership/signature', element: <MembershipSignature /> },
            {
              path: 'settings',
              element: <SettingsLayout />,
              children: [
                { path: 'profile', element: <Profile />, index: true },
                { path: 'preferences', element: <Preferences />, index: true },
                { path: 'notifications', element: <Notifications />, index: true },
              ],
            },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        {
          path: 'signin',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const OAuth = Loadable(lazy(() => import('../pages/auth/OAuth')));

// DASHBOARD
// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));

// SETTINGS
const Profile = Loadable(lazy(() => import('../pages/settings/Profile')));
const Preferences = Loadable(lazy(() => import('../pages/settings/Preferences')));
const Notifications = Loadable(lazy(() => import('../pages/settings/Notifications')));

// CLUB
const Schedule = Loadable(lazy(() => import('../pages/club/Schedule')));
const Memberships = Loadable(lazy(() => import('../pages/club/Memberships')));

const PurchaseMembership = Loadable(lazy(() => import('../pages/club/PurchaseMembership')));

const Redeem = Loadable(lazy(() => import('../pages/club/Redeem')));
const Invoice = Loadable(lazy(() => import('../pages/club/Invoice')));

const ClubDashboard = Loadable(lazy(() => import('../pages/club/Dashboard')));
const ClubClasses = Loadable(lazy(() => import('../pages/club/Classes')));
const ClubMembership = Loadable(lazy(() => import('../pages/club/Membership')));
const ClubPaymentDetails = Loadable(lazy(() => import('../pages/club/PaymentDetails')));
const ClubCancelMembership = Loadable(lazy(() => import('../pages/club/CancelMembership')));
const ClubChangePaymentFrequency = Loadable(
  lazy(() => import('../pages/club/ChangePaymentFrequency'))
);
const ClubSuspendMembership = Loadable(lazy(() => import('../pages/club/SuspendMembership')));

const MembershipPayment = Loadable(
  lazy(() => import('../pages/club/MembershipSelect/MembershipPayment'))
);
const MembershipSignature = Loadable(
  lazy(() => import('../pages/club/MembershipSelect/MembershipSignature'))
);

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
