// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar, { Props as AvatarProps } from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  const { currentMember } = useAuth();

  return (
    <Avatar
      src={currentMember?.profilePictureUrl || ''}
      alt={currentMember?.givenName}
      color={
        currentMember?.profilePictureUrl
          ? 'default'
          : createAvatar(`${currentMember?.givenName} ${currentMember?.familyName}`).color
      }
      {...other}
    >
      {createAvatar(`${currentMember?.givenName} ${currentMember?.familyName}`).name}
    </Avatar>
  );
}
