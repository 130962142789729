import { capitalCase } from 'change-case';
// @mui
import { Box, Container, Tab, Tabs } from '@mui/material';
// routes
import { PATHS } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
import useTabs from '../../hooks/useTabs';
// components
import { Outlet, useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';

// ----------------------------------------------------------------------

export default function SettingsLayout() {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const location = useLocation();

  const ACCOUNT_TABS = [
    {
      value: 'profile',
      icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
      path: 'profile',
    },
    {
      value: 'preferences',
      icon: <Iconify icon={'healthicons:ui-preferences'} width={20} height={20} />,
      path: 'preferences',
    },
    {
      value: 'notifications',
      icon: <Iconify icon={'eva:bell-fill'} width={20} height={20} />,
      path: 'notifications',
    },
    {
      value: 'changePassword',
      icon: <Iconify icon={'ic:round-vpn-key'} width={20} height={20} />,
      path: 'changePassword',
    },
  ];

  const defaultTab = () => {
    const matchingTab = ACCOUNT_TABS.find((tab) => location.pathname.includes(tab.value));
    return matchingTab?.value || 'profile';
  };

  const { currentTab, onChangeTab } = useTabs(defaultTab());

  return (
    <Page title="Settings">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs heading="Settings" links={[{ name: 'Settings', href: PATHS.root }]} />

        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={onChangeTab}
        >
          {ACCOUNT_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={capitalCase(tab.value)}
              icon={tab.icon}
              value={tab.value}
              onClick={() => navigate(tab.path)}
            />
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        <Outlet />
      </Container>
    </Page>
  );
}
