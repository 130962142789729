// @mui
import { styled } from '@mui/material/styles';

import Box, { BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
//
import Image from './Image';

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(8, 2),
}));

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  title: string;
  img?: string;
  description?: string;
  actionText?: string;
  onActionClick?: () => void;
  width?: number;
}

export default function EmptyContent({
  title,
  description,
  img,
  actionText,
  onActionClick,
  width,
  ...other
}: Props) {
  return (
    <RootStyle {...other}>
      <Box>
        <Image
          disabledEffect
          visibleByDefault
          alt="empty content"
          src={img || '/assets/illustrations/illustration_empty_content.svg'}
          sx={{ height: 120, mb: 3, width }}
        />
      </Box>

      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>

      {description && (
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {description}
        </Typography>
      )}

      {actionText && (
        <Button sx={{ my: 3 }} variant="contained" onClick={onActionClick}>
          {actionText}
        </Button>
      )}
    </RootStyle>
  );
}
