// routes
import { PATHS } from '../../../routes/paths';
// components

import Iconify from 'src/components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  membership: <Iconify icon={'ic:baseline-card-membership'} width={20} height={20} />,
  trial: <Iconify icon={'akar-icons:clock'} width={20} height={20} />,
  bundle: <Iconify icon={'fluent:stack-16-regular'} width={20} height={20} />,
  inventory: <Iconify icon={'clarity:bundle-line'} width={20} height={20} />,
  templates: <Iconify icon={'fluent:library-20-filled'} width={20} height={20} />,
  payments: <Iconify icon={'ic:outline-payments'} width={20} height={20} />,
  banking: getIcon('ic_banking'),
  class: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const navConfig = (clubId: string) => [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Menu',
    items: [
      { title: 'Home', path: PATHS.club.dashboard(clubId), icon: ICONS.dashboard },
      {
        title: 'Classes',
        path: PATHS.club.classes(clubId),
        icon: ICONS.class,
      },
      {
        title: 'Membership',
        path: PATHS.club.membership(clubId),
        icon: ICONS.membership,
      },
      {
        title: 'Payments',
        path: PATHS.club.paymentDetails(clubId),
        icon: ICONS.payments,
      },
    ],
  },
];

export default navConfig;
