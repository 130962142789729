// @mui
import {
  Avatar,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { TableSkeleton } from 'src/components/table';
import { GymGoerMembership, Member, useGetMemberQuery } from 'src/graphql/generated';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

type AddTaskModalProps = {
  onCancel: VoidFunction;
};

export default function MemberSelectModal({ onCancel }: AddTaskModalProps) {
  const { setMemberId, user } = useAuth();

  const handleMemberClicked = (memberId?: string | null) => {
    if (!memberId) {
      return;
    }
    setMemberId(memberId);
    onCancel();
  };

  return (
    <>
      <DialogTitle>Switch Profile</DialogTitle>
      <DialogContent>
        <Divider sx={{ borderStyle: 'dashed', mb: 3 }} />
        {user?.memberships?.map((m) => (
          <MembershipRow
            membership={m}
            key={m?.memberId}
            handleClick={() => handleMemberClicked(m?.memberId)}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined">Cancel</Button>
      </DialogActions>
    </>
  );
}

const MembershipRow = ({
  membership,
  handleClick,
}: {
  membership: GymGoerMembership | null;
  handleClick: VoidFunction;
}) => {
  const { data, loading } = useGetMemberQuery({
    variables: {
      id: membership?.memberId || '',
    },
  });

  const member = data?.getMember as Member;
  console.log({ membership });

  if (loading) {
    return <TableSkeleton />;
  }
  if (!member) {
    return null;
  }
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ mb: 2, cursor: 'pointer' }}
      onClick={handleClick}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        <Avatar alt={member.displayName} src={member?.profilePictureUrl || ''} />
        <Typography variant="subtitle2">{member.displayName}</Typography>
        {membership?.isDependent === true && (
          <Typography variant="subtitle2" color="GrayText">
            (Dependent)
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
