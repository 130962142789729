import { createContext, ReactNode } from 'react';
// hooks
import useLocalStorage from '../hooks/useLocalStorage';
// utils
import { Club } from 'src/graphql/generated';

export type ClubContextProps = {
  club?: Club;

  // Reset
  onResetClub: VoidFunction;
  onSetClub: (club: Club | null) => void;
};

// ----------------------------------------------------------------------

const initialState: ClubContextProps = {
  // Mode
  onResetClub: () => {},
  onSetClub: () => {},
};

const ClubContext = createContext(initialState);

// ----------------------------------------------------------------------

type ClubProviderProps = {
  children: ReactNode;
};

function ClubProvider({ children }: ClubProviderProps) {
  const [state, setClub] = useLocalStorage('club', {
    club: initialState.club,
  });

  const onSetClub = (club: Club) => {
    setClub({
      club: club,
    });
  };

  // Reset

  const onResetClub = () => {
    setClub({
      club: null,
    });
  };

  return (
    <ClubContext.Provider
      value={{
        ...state,

        // Reset
        onResetClub,
        onSetClub,
      }}
    >
      {children}
    </ClubContext.Provider>
  );
}

export { ClubProvider, ClubContext };
