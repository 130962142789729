import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
// @mui
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
// hooks

import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
//
import LoadingScreen from 'src/components/LoadingScreen';
import { Club, useGetClubLazyQuery } from 'src/graphql/generated';
import useAuth from 'src/hooks/useAuth';
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';

// ----------------------------------------------------------------------

type MainStyleProps = {
  collapseClick: boolean;
};

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export type ClubLayoutContext = {
  club: Club;
};

export default function SecureClubLayout() {
  const { setCurrentClub } = useAuth();
  const { collapseClick, isCollapse } = useCollapseDrawer();

  const [open, setOpen] = useState(false);

  const params = useParams();
  const clubId = params.clubId || 'Unknown';

  const [getClubLazyQuery, { data, loading }] = useGetClubLazyQuery({
    context: { clientName: 'public' },
    variables: {
      id: clubId,
    },
  });

  useEffect(() => {
    const loadClub = async () => {
      const response = await getClubLazyQuery();
      const club = response.data?.getClub as Club;
      if (club) {
        setCurrentClub(club);
      }
    };
    loadClub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const club = data?.getClub as Club;

  if (loading) {
    return <LoadingScreen />;
  }

  if (!club) {
    return null;
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} club={club} />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} club={club} />

      <MainStyle collapseClick={collapseClick}>
        <Outlet context={{ club }} />
      </MainStyle>
    </Box>
  );
}
